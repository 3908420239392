<template>
  <div>
    <p class="mt-5 mb-2 text-center font-size-26 text-black font-weight-bold">{{ $t('auth.resetPassword') }}</p>
    <p class="text-center font-size-22  text-black font-weight-bold">{{$t('auth.resetPasswordContent')}}</p>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form class="position-relative form-top p-4" @submit.prevent="handleSubmit(resetPassword)">
        <b-row>
          <b-col lg="12">
            <country-code
                class="mb-3 font-size-18"
                :validate="'required|numeric|min:9|max:11'"
                :placeholder="$t('auth.phoneNumber')"
                :name="$t('auth.phoneNumber')"
                :label="$t('auth.phoneNumber')"
                v-model="userInfo.user"
                id="phone-code-primary_phone_number"
                @onSelect="onSelect"
            />
          </b-col>
          <b-col lg="12">
            <div class="mb-2 mt-3 d-flex justify-content-center">
              <b-button class=" text-white w-25 py-2" variant="warning" type="button" v-if="loadingChecking">
                <spinner-loading :text="checkIfCheckingUser ? 'تحميل' : 'يتم التحقيق'"/>
              </b-button>
              <b-button class=" text-white w-25 py-2" variant="warning" type="submit" v-else>
                <span>{{checkIfCheckingUser ? 'ارسال' : $t('auth.check') }} </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import authService from '@/modules/auth/services/auth'
import { core } from '@/config/pluginInit'

export default {
  name: 'forgotPassword',
  data () {
    return {
      loadingChecking: false,
      checkIfCheckingUser: false,
      userInfo: {
        user: '',
        country_code: ''
      }
    }
  },
  methods: {
    onSelect (data) {
      this.userInfo.country_code = data
    },
    goToOtp () {
      authService.forgetPassword(this.userInfo).then(res => {
        localStorage.setItem('verifyData', JSON.stringify({
          phone: this.userInfo.user,
          country_code: this.userInfo.country_code
        }))
        this.$router.push({ name: 'resetPassword' })
      })
    },
    checkUser () {
      this.loadingChecking = true
      authService.checkUser(this.userInfo).then((res) => {
        core.showSnackbar('success', res.data.message)
        this.goToOtp()
      }).finally(() => {
        this.loadingChecking = false
      })
    },
    resetPassword () {
      if (this.checkIfCheckingUser) {
        this.goToOtp()
      } else {
        this.checkUser()
      }
    }
  }
}
</script>

<style>

</style>
